import { createSlice } from '@reduxjs/toolkit';
import { createEncryptedAsyncThunk } from '../../Utility/APIUtils';

const initialState = {
  commonDeleteResponse: {
    status: false,
    message: '',
  },
  commonDeleteSucces: false,
  error: '',
  loading: false,
};

export const adminLedgerReport = createEncryptedAsyncThunk(
  'adminReportSlice/adminLedgerReport',
  'Wallet/GetLedgerDetail',
  {
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      ledger_user_id: params.selectRetailer.value,
      service_Id: 0,
      status: Array.isArray(params.transactype)
        ? params.transactype
        : [Number(params.transactype)],
      transaction_type: '',
      req_startDate: params.fromDate,
      req_endDate: params.toDate,
    }),
  },
);
export const adminBillPaymentReport = createEncryptedAsyncThunk(
  'adminReportSlice/adminBillPaymentReport',
  'account/GetBBPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      bbps_userid: params.selectRetailer.value,
      txn_id: '',
      txn_type: '',
      txn_mode: '',
      customerNo: '',
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const getRetailerList = createEncryptedAsyncThunk(
  'adminReportSlice/adminBillPaymentReport',
  (params) =>
    `/Reports/UserLedgerReport?MobileNo=${params.selectRetailer.mobile}&FromDate=${params.fromDate}&ToDate=${params.toDate}&Role=${params.Role}`,
  {
    method: 'GET',
  },
);
export const getAdminMoneyTransactionHistoryHold = createEncryptedAsyncThunk(
  'adminReportSlice/getAdminMoneyTransactionHistoryHold',
  'account/GetDMTTransaction',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      txn_id: '',
      dmt_transaction_userid: params.selectDistributor.value,
      startDate: params.fromDate ? params.fromDate : '',
      endDate: params.toDate ? params.toDate : '',
    }),
  },
);
export const AdminAEPSTransactionReports = createEncryptedAsyncThunk(
  'reportSlice/AdminAEPSTransactionReports',
  'account/GetAEPSReport',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userId: params.currentUser.userGuid,
      report_userid: params.selectRetailer ? params.selectRetailer.value : params.selectRetailer.value,
      txn_id: '',
      apiId: 0,
      startDate: params.fromDate,
      endDate: params.toDate,
    }),
  },
);
export const confirmSuccessManually = createEncryptedAsyncThunk(
  'adminReportSlice/confirmSuccessManually',
  'Transaction/UpdateTransStatus',
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 1,
      userID: 85,
    }),
  },
);
export const confirmFailedManually = createEncryptedAsyncThunk(
  'adminReportSlice/confirmFailedManually',
  'Transaction/UpdateTransStatus',
  {
    prepareBody: (params) => ({
      walletId: params.dmtId,
      status: 3,
      userID: 85,
    }),
  },
);
export const kycPayementReports = createEncryptedAsyncThunk(
  'adminReportSlice/confirmFailedManually',
  'account/CFPGGetAllPG',
  {
    transUrl: process.env.REACT_APP_API_TRANSACTION,
    prepareBody: (params) => ({
      userID: params.currentUser.userGuid,
      pG_transaction_userid:params.currentUser.userId,
      transactionid: '',
      startDate: params.fromDate,
      endDate: params.toDate,
      status: [0,1,2],
    }),
  },
);

export const adminReportSlice = createSlice({
  name: 'adminReportSlice',
  initialState,
  reducers: {
    FetchSuccess(state, action) {
      return { ...state, recharge: action.payload.Data[0] };
    },
    FetchFailed(state, action) {
      return { ...state, rechargeResponse: { status: true, message: action.payload } };
    },
    DeleteSuccess(state) {
      return { ...state, loading: false, commonDeleteSucces: true };
    },
    DeleteFailed(state, action) {
      return {
        ...state,
        fetchError: false,
        commonDeleteResponse: { status: true, message: action.payload },
      };
    },
    DeleteInitial(state) {
      return {
        ...state,
        commonDeleteSucces: false,
        commonDeleteResponse: { status: false, message: '' },
      };
    },
  },
});
export const { FetchSuccess, FetchFailed, DeleteSuccess, DeleteFailed, DeleteInitial } =
  adminReportSlice.actions;
export default adminReportSlice.reducer;
